/* eslint no-console:0 */
// 公開側用 Javascript エンドポイント

import $ from 'jquery';
window.$ = $;
import '@hotwired/turbo-rails';
import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

const images = require.context('./images', true)
const imagePath = name => images(name, true)

import "@fortawesome/fontawesome-free/js/all"
import './controllers'
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-ja');
require('lightcase/src/js/lightcase');
require('lightcase/src/css/lightcase.css');

import './slider.js';
import './object_fit_images.js';
import './tab.js';
import './modal.js';
import './back_to_top.js';
import './mobile_menu.js';
import './scroll_to_somewhere.js';
import './set_options.js';
import './cascading_select.js';
import 'slick-carousel';
import 'jquery-ellipsis';
import 'readmore-js'
import './ellipsis.js';
import './clear_form.js';
