$(document).on('ready turbo:load', function() {
  $(".reset-button").bind("click", function(){
    $(this).parents('form').find(':input').each(function() {
      switch(this.type){
      case 'password':
      case 'select-multiple':
      case 'select-one':
      case 'text':
      case 'textarea':
        $(this).val('');
        break;
      case 'checkbox':
      case 'radio':
        this.checked = false;
      }
    });
  });
});
