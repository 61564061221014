import _ from 'lodash';

class CascadingSelect {
  constructor(selectorOrElement, childSelectorOrElement, includeBlank) {
    this.selectorOrElement = selectorOrElement;
    this.childSelectorOrElement = childSelectorOrElement;
    this.includeBlank = includeBlank;

    this._backupElement(childSelectorOrElement);
    this._setupSelectHandler();
    // ブラウザで「戻る」したときなどに、まだ select の値が設定されてない状態で起動してしまう
    // ことがあるので、少し遅らせる。
    const self = this;
    setTimeout(() => {
      self._initChild();
    }, 1);
  }

  _backupElement(childSelectorOrElement) {
    const el = $(childSelectorOrElement);
    if (el.length === 0) {
      return;
    }

    const clonedId = el.attr('id') + '_cloned';
    const cloned = $('#' + clonedId);
    if (cloned.length === 0) {
      const cloned = el.clone();
      cloned.addClass('hidden');
      cloned.attr('name', '');
      cloned.attr('id', clonedId);
      cloned.insertAfter(childSelectorOrElement);
    }
  }

  _setupSelectHandler() {
    const $self = $(this.selectorOrElement);
    $self.change(() => {
      const selectedParent = $self.find(':selected').text();
      const optgroups = $(this.childSelectorOrElement + '_cloned').find('optgroup').toArray();
      const model = optgroups.reduce((acc, optgroup) => {
        const $optgroup = $(optgroup);
        acc[$optgroup.attr('label')] = $optgroup.html();
        return acc;
      }, {});

      let childHtml = '';
      const childCount = $(model[selectedParent]).filter('option').length;
      if (_.isString(this.includeBlank) && childCount != 1) {
        childHtml = `<option value>${this.includeBlank}</option>`;
      }
      if (model[selectedParent]) {
        childHtml = childHtml + model[selectedParent];
      }
      $(this.childSelectorOrElement).html(childHtml);
      $(this.childSelectorOrElement).trigger('change');
    });
  }
  _initChild() {
    $(this.selectorOrElement).trigger('change');
  }
}
export default CascadingSelect;
window.CascadingSelect = CascadingSelect;
