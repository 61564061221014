var menuStatus = 'closed';
var scrollpos;

$(document).on('ready turbo:load', function() {
  $('#menu-button').on('click', function(e) {
    e.preventDefault();
    toggleMenu();
  });
});

function toggleMenu(speed) {
  if (speed == null) speed = 500;
  $('#mobile-menu').animate({opacity: 'toggle'}, speed, 'linear');
  if (menuStatus === 'closed') {
    scrollpos = $(window).scrollTop();
    $('body, html').addClass('menu-fixed').css({'top': -scrollpos});
    $('#menu-button').addClass('opened');
    $('#menu-button').removeClass('closed');
    menuStatus = 'opened';
  } else {
    $('#menu-button').addClass('closed');
    $('#menu-button').removeClass('opened');
    $('body, html').removeClass('menu-fixed').css({'top': 0});
    if (speed == 0) {
      $.when(
        $("span[class^='line-']").css('transition', 'all 0s ease-in-out')
      ).done ( function() {
        window.scrollTo( 0 , scrollpos );
      });
    } else {
      $("span[class^='line-']").css('transition', 'all .5s ease-in-out');
      window.scrollTo( 0 , scrollpos );
    }
    menuStatus = 'closed';
  }
}
