$(document).on('ready turbo:load', function() {

  var numLoadedImagesOfHero = 0;
  var numLoadedImagesOfBanners = 0;

  function showIfAllImagesLoaded(event, slick, image, imageSource) {
    numLoadedImagesOfHero += 1;
    if (numLoadedImagesOfHero == $(this).find('img').length) {
      showSlideImage(this);
    }
  }

  function showIfAllBannerImagesLoaded(event, slick, image, imageSource) {
    numLoadedImagesOfBanners += 1;
    if (numLoadedImagesOfBanners == $(this).find('img').length) {
      showSlideImage(this);
    }
  }

  function showSlideImage(slide) {
    $(slide).addClass('fade');
    $('.slick-prev', slide).addClass('fade');
    $('.slick-next', slide).addClass('fade');
    $(slide).css('visibility','visible');
    $('.slick-prev', slide).css('visibility','visible');
    $('.slick-next', slide).css('visibility','visible');
  }

  $('#page-home #hero .slider').on("init", function() {
    $('.slick-slide[data-slick-index="0"]').addClass("add-animation");
  }).slick({
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoPlaySpeed: 0,
    fade: true,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    dots: true,
    lazyLoad: 'progressive',
    zIndex: 10
  }).on({
    // スライドが移動する前に発生するイベント
    beforeChange: function (event, slick, currentSlide, nextSlide) {
      // 表示されているスライドに"add-animation"のclassをつける
      $(".slick-slide", this).eq(nextSlide).addClass("add-animation");
      // あとで"add-animation"のclassを消すための"remove-animation"classを付ける
      $(".slick-slide", this).eq(currentSlide).addClass("remove-animation");
    },
    // スライドが移動した後に発生するイベント
    afterChange: function () {
      // 表示していないスライドはアニメーションのclassを外す
      $(".remove-animation", this).removeClass(
        "remove-animation add-animation"
      );
    },
  });

  $('#page-home-mobile #hero .slider').slick({
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoPlaySpeed: 0,
    fade: true,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    dots: true,
    lazyLoad: 'progressive',
    responsive: [
      ,{ breakpoint: 1150 ,settings: { centerMode: true , centerPadding: '12%' } }
      ,{ breakpoint: 1050 ,settings: { centerMode: true , centerPadding: '24%' } }
      ,{ breakpoint: 768 ,settings: { centerMode: true , centerPadding: '22%' } }
      ,{ breakpoint: 668 ,settings: { centerMode: true , centerPadding: '19%' } }
      ,{ breakpoint: 569 ,settings: { centerMode: true , centerPadding: '14%' } }
      ,{ breakpoint: 480 , settings: { centerMode: true, centerPadding: '0' } }
    ]
  });
  $('#hero .slider').on('lazyLoaded', showIfAllImagesLoaded);

  $('#banners .slider').slick({
    centerMode: true
    ,arrows: false
    ,centerPadding: '30px'
    ,slidesToShow: 3
    ,infinite: true
    ,lazyLoad: 'progressive'
    ,responsive: [
      {
        breakpoint: 480
        ,settings: {
          centerPadding: '20px'
          ,slidesToShow: 1
        }
      }
    ]
  });
  $('#banners .slider').on('lazyLoaded', showIfAllBannerImagesLoaded);

  $('#page-home #open-house-and-events .slider').slick({
    centerPadding: '0px'
    ,slidesToShow: 1
    ,slidesToScroll: 1
    ,infinite: true
    ,rows: 2
    ,slidesPerRow: 2
    ,dots: true
  });

  $('#page-home-mobile #open-house-and-events .slider').slick({
    centerPadding: '0px'
    ,slidesToShow: 1
    ,slidesToScroll: 1
    ,infinite: true
    ,rows: 2
    ,slidesPerRow: 1
    ,dots: true
  });

  $('#page-home #recommended-past-works .slider, #page-home #recommended-articles .slider').slick({
    slidesToShow: 3
    ,slidesToScroll: 1
    ,infinite: true
    ,dots: true
  });

  $('#page-home-mobile #recommended-past-works .slider, #page-home-mobile #recommended-articles .slider').slick({
    slidesToShow: 2
    ,slidesToScroll: 1
    ,infinite: true
    ,dots: true
    ,responsive: [
      {
        breakpoint: 767
        ,settings: {
          centerMode: true
          ,centerPadding: '0px'
          ,slidesToShow: 1
        }
      }
    ]
  });

  $('#page-home #past-work-photos .slider').slick({
    slidesToShow: 4
    ,slidesToScroll: 4
    ,infinite: true
    ,dots: true
  });

  $('#page-home-mobile #past-work-photos .slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
  });

  $('#other-events .slider').slick({
    centerPadding: '0px'
    ,slidesToShow: 1
    ,arrows: true
    ,dots: true
    ,infinite: true
  });

  $('#page-past-works #past-works-list .slider').slick({
    slidesToShow: 2
    ,slidesToScroll: 1
    ,infinite: true
    ,arrows: true
    ,centerMode: true
    ,centerPadding: '0px'
    ,dots: true
    ,responsive: [
      {
        breakpoint: 767
        ,settings: {
          slidesToShow: 1
          ,centerMode: true
          ,centerPadding: '0px'
        }
      }
    ]
  });

});
