$(document).on('ready turbo:load', function() {
  $('.set-button').on('click', function(e) {

    var selectedPrefecture = $(this).closest('.options').find('select[id$=_prefecture_id] option:selected').text();
    var selectedArea = $(this).closest('.options').find('select[id$=_area_id] option:selected').text();
    var selectedCity = $(this).closest('.options').find('select[id$=_city_id] option:selected').text();
    var checkedElements = $(this).closest('.options').find("input[type='checkbox']:checked, input[type='radio']:checked");
    var keyword = $(this).closest('.options').find("input[type='search']").val();
    var buildingCostLow = $(this).closest('.options').find('#company_search_form_building_cost_low option:selected').text();
    var buildingCostHigh = $(this).closest('.options').find('#company_search_form_building_cost_high option:selected').text();
    var housingExhibitionBegin = $(this).closest('.options').find('#housing_exhibition_search_form_begin_on').val();
    var housingExhibitionEnd = $(this).closest('.options').find('#housing_exhibition_search_form_end_on').val();
    var priceRangeMin = $(this).closest('.options').find('#past_work_search_form_price_range_min option:selected').text();
    var priceRangeMax = $(this).closest('.options').find('#past_work_search_form_price_range_max option:selected').text();
    var text = '';

    if (selectedPrefecture) {
      text += selectedPrefecture;
      text += selectedArea == '' ? '' : ', ' + selectedArea;
      text += selectedCity == '' ? '' : ', ' + selectedCity;
    }

    checkedElements.each(function(index, elm) {
      text += $('label[for="' + $(elm).attr('id') + '"]').text();
      if (checkedElements.length != index + 1) {
        text += ', ';
      }
    });

    if (buildingCostLow || buildingCostHigh) {
      if (buildingCostLow !== '下限なし' && buildingCostHigh !== '上限なし') {
        text += buildingCostLow;
        text += "〜";
        text += buildingCostHigh;
      } else if (buildingCostLow !== '下限なし') {
        text += buildingCostLow;
        text += "〜";
      } else if (buildingCostHigh !== '上限なし') {
        text += "〜";
        text += buildingCostHigh;
      } else {
        text = '';
      }
    }

    if (priceRangeMin || priceRangeMax) {
      if (priceRangeMin !== '下限なし' && priceRangeMax !== '上限なし') {
        text += priceRangeMin;
        text += "〜";
        text += priceRangeMax;
      } else if (priceRangeMin !== '下限なし') {
        text += priceRangeMin;
        text += "〜";
      } else if (priceRangeMax !== '上限なし') {
        text += "〜";
        text += priceRangeMax;
      } else {
        text = '';
      }
    }

    if (keyword) {
      text += keyword;
    }

    if (housingExhibitionBegin || housingExhibitionEnd) {
      text += housingExhibitionBegin + ' 〜 ' + housingExhibitionEnd;
    }

    text = text.replace(/undefined/g, "");
    text = text.replace(/選択してください/g, "");
    text = text.replace(/, 指定なし/g, "");

    $(this).closest('.options').next().html(text);
  });

  $('.reset-button').on('click', function(e) {
    $('.selected').text('');
  });

  if ($('.set-button')[0]) {
    $('.set-button').each(function(_, e) {
      $(e).click()
    });
  }
});
