$(document).on('ready turbo:load', function() {
  $('.accordion-tabs .tab-content').hide();
  $('.accordion-tabs').find('.is-active').next().addClass('is-open').show();
  $('.accordion-tabs').on('click', 'li > a.tab-link', function (event) {
    if (!$(this).hasClass('is-active')) {
      event.preventDefault();
      var accordionTabs = $(this).closest('.accordion-tabs');
      accordionTabs.find('.is-open input').prop('checked', false);
      accordionTabs.find('.is-open').removeClass('is-open').hide();

      $(this).next().toggleClass('is-open').toggle();
      accordionTabs.find('.is-active').removeClass('is-active');

      $(this).addClass('is-active');
    } else {
      event.preventDefault();
    }
  });
});
